import React, { Component } from "react";

class Services extends Component {
  render() {
    return (
      <div>
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* Top Background Image Wrapper */}
        <div
          className="bgded overlay"
          style={{
            backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/demo/gallery/hospitals/6.jpg)`
          }}
        >
          {/* ################################################################################################ */}
          <div className="wrapper row0">
            <div id="topbar" className="hoc clear">
              {/* ################################################################################################ */}
              <div className="fl_left">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <i className="fas fa-phone" /> (+91)8700230989
                  </li>
                  <li>
                    <i className="far fa-envelope" />
                    gautamfacilityservices@gmail.com
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              <div className="fl_right">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <a href="../home">
                      <i className="fas fa-home" />
                    </a>
                  </li>
                  <li>
                    <a href="contact" title="Help Centre">
                      <i className="fas fa-life-ring" />
                    </a>
                  </li>
                  <li>
                    <a href="signin" title="Login">
                      <i class="fas fa-sign-in-alt"></i>
                    </a>
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              {/* ################################################################################################ */}
            </div>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div className="wrapper row1">
            <header id="header" className="hoc clear">
              {/* ################################################################################################ */}
              <div id="logo" className="fl_left">
                <h1>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/demo/backgrounds/company.png"
                    }
                    style={{ width: "65px" }}
                  />
                  <a href="../">Gautam Facility Services</a>
                </h1>
              </div>
              {/* ################################################################################################ */}
              <nav id="mainav" className="fl_right">
                <ul className="clear">
                  <li>
                    <a href="../home">Home</a>
                  </li>
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li className="active">
                    <a href="services">Our services</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </nav>
              {/* ################################################################################################ */}
            </header>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div id="breadcrumb" className="hoc clear">
            {/* ################################################################################################ */}
            <h6 className="heading">Our Services</h6>
            {/* ################################################################################################ */}
          </div>
          {/* ################################################################################################ */}
        </div>
        {/* End Top Background Image Wrapper */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row3">
          <main className="hoc container clear">
            {/* main body */}
            {/* ################################################################################################ */}
            <div className="content">
              {/* ################################################################################################ */}
              <h1>Hospital House Keeping Services </h1>
              <p>
                With Smart Cleaning technologies eliminating Nosocomil Infection
                by ensuring strict adherence to hygiene and cleanliness
                standards. Keeping hospital rooms and common area with regular
                sanatisation and cleaning services is healthly for patients,
                visitors and hospital staff.{" "}
              </p>
              <h1>Manpower Services</h1>
              <p>
                We are engaged in providing manpower services for Hospitals,
                Schools and Colleges to ensure client service efficiency
                assisting them with providing <br />  Cleaners <br /> Kitchen
                staff
                <br /> Maid/Mani services
                <br />  Chauffeurs
                <br /> General Duty Associates(GDA)
              </p>
            </div>
            {/* ################################################################################################ */}
          </main>
        </div>
        {/* ################################################################################################ */}
        {/* / main body */}
        <div className="clear" />
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row4">
          <footer id="footer" className="hoc clear">
            {/* ################################################################################################ */}
            <hr className="btmspace-50" />
            {/* ################################################################################################ */}
            <div className="group">
              <div className="one_quarter first">
                <h6 className="heading">Contact info</h6>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <address style={{marginRight: "-12px"}}>
                    N-12 K.N Complex Lajpat Nagar - 2 
                    New Delhi-110024
                    </address>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    (+91)8700230989,
                    <br /> (011) 29831411
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>{" "} <p style={{marginRight: "-6px"}}>gautamfacilityservices@gmail.com</p>
                  </li>
                </ul>
                <ul className="faico clear">
                  <li>
                    <a className="faicon-facebook" href="#">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-google-plus" href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-linkedin" href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-twitter" href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-vk" href="#">
                      <i className="fab fa-vk" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Services</h6>
                <p className="nospace btmspace-15"></p>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fas fa-check-square" />
                    Hospital Housekeeping Services
                  </li>
                 
                  <li>
                    <i className="fas fas fa-check-square" />
                    Manpower Services
                  </li>
                </ul>
                <p />
              </div>
              <div className="one_quarter">
                <h6 className="heading">Read about us</h6>
                <ul className="nospace linklist">
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="profile">Company Profile</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Clients</h6>
                <ul className="nospace clear latestimg">
                  <li>
                    <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/7.png"
                      } alt="" />
                    </a>
                  </li>
                  <li>
                  <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/01.png"
                      } alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* ################################################################################################ */}
          </footer>
        </div>
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
      </div>
    );
  }
}

export default Services;
