import React, { Component } from "react";
import "../../layout/styles/layout.css";

class About extends Component {
  render() {
    return (
      <div>
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* Top Background Image Wrapper */}
        <div
          className="bgded overlay"
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80")',
          }}
        >
          {/* ################################################################################################ */}
          <div className="wrapper row0">
            <div id="topbar" className="hoc clear">
              {/* ################################################################################################ */}
              <div className="fl_left">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <i className="fas fa-phone" /> (+91)8700230989
                  </li>
                  <li>
                    <i className="far fa-envelope" />
                    gautamfacilityservices@gmail.com
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              <div className="fl_right">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <a href="../home">
                      <i className="fas fa-home" />
                    </a>
                  </li>
                  <li>
                    <a href="contact" title="Help Centre">
                      <i className="fas fa-life-ring" />
                    </a>
                  </li>
                  <li>
                    <a href="signin" title="Login">
                      <i class="fas fa-sign-in-alt"></i>
                    </a>
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              {/* ################################################################################################ */}
            </div>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div className="wrapper row1">
            <header id="header" className="hoc clear">
              {/* ################################################################################################ */}
              <div id="logo" className="fl_left">
                <h1>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/demo/backgrounds/company.png"
                    }
                    style={{  width: "65px" }}
                    alt="StarCare"
                  />
                  <a href="../home">Gautam Facility Services</a>
                </h1>
              </div>
              {/* ################################################################################################ */}
              <nav id="mainav" className="fl_right">
                <ul className="clear">
                  <li>
                    <a href="../home">Home</a>
                  </li>
                  <li className="active">
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="services">Our Services</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </nav>
              {/* ################################################################################################ */}
            </header>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div id="breadcrumb" className="hoc clear">
            {/* ################################################################################################ */}
            <h6 className="heading">About Us</h6>
            {/* ################################################################################################ */}
          </div>
          {/* ################################################################################################ */}
        </div>
        {/* End Top Background Image Wrapper */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row3">
          <main className="hoc container clear">
            {/* main body */}
            {/* ################################################################################################ */}
            <div className="content">
              {/* ################################################################################################ */}
              <h1>About Us</h1>
              <p>
                Established in the year 2010, we, “Gautam Facility Services” are one of the well‐known organizations engaged in
                providing Facility Services for the clients. Our mentor
                “Mr. Nand Kumar” is a man with vast industry experience and rich
                business ethics. He ensures that every activity of our
                organization keeping the professional ethics intact. Our
                portfolio of services include <br />
                <br />
              </p>
              <h5> Facility Services</h5>
              <p>
                Facility Management Services plays a critical with the
                healthcare environment directly impacting the patient experience
                and enabling the efficient provision of cleanincal care. Star
                Care catering offers trusted and eminent facility services
                ranging from house keeping services to other Facility Management
                Services by latest housekeeping equipments and materials with
                the help of trained and experienced professionals. It primary
                includes daily cleaning services to the cleanical wards,
                hospital offices, common areas, call rooms, car parks and other
                outdoor areas. We also provide movement of patients, medical
                records and other medical supplies. Adhoge support cleaning
                services during emergency period.
              </p>
              <br />
              <strong>
                <h1>Vision</h1>
              </strong>
              <p>
                Gautam Facility Services will be one of the most authentic and leading
                manpower contractor within the hospitality industry and will
                offer both manpower and facility management.
              </p>
              <h1>Mission</h1>
              <p>
                Gautam Facility Services with over vast years of experience strives
                towards best in class service and excellent manpower services. <br />
                <br />
                 We serve the best food and exceptional service experience to
                our clients.
                <br />
                 The objective is to full fill the customer needs no matter
                challenging it may be.
                <br />
                 Recognition of being preferred as a regular vender.
                <br />
              </p>
              <h1>Our Values</h1>
              <p>
                Star Catering Team have desire to repeatedly exceed service
                expectation of customers
                <br />
                <br />
                 Our business actions will always pertain to highest ethical
                standards
                <br />
                 Open and Transparent relationship with clients
                <br />
                 Giving best value for the agreed quality
                <br /> Safety of our people, environment and property are our
                priorities.
              </p>
            </div>
            {/* ################################################################################################ */}
          </main>
        </div>
        {/* ################################################################################################ */}
        {/* / main body */}
        <div className="clear" />
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row4">
          <footer id="footer" className="hoc clear">
            {/* ################################################################################################ */}
            <hr className="btmspace-50" />
            {/* ################################################################################################ */}
            <div className="group">
              <div className="one_quarter first">
                <h6 className="heading">Contact info</h6>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <address style={{marginRight: "-12px"}}>
                    N-12 K.N Complex Lajpat Nagar - 2 
                    New Delhi-110024
                    </address>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    (+91)8700230989,
                    <br /> (011) 29831411
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>{" "} <p style={{marginRight: "-6px"}}>gautamfacilityservices@gmail.com</p>
                  </li>
                </ul>
                <ul className="faico clear">
                  <li>
                    <a className="faicon-facebook" href="#">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-google-plus" href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-linkedin" href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-twitter" href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-vk" href="#">
                      <i className="fab fa-vk" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Services</h6>
                <p className="nospace btmspace-15"></p>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fas fa-check-square" />
                    Hospital Housekeeping Services
                  </li>
                 
                  <li>
                    <i className="fas fas fa-check-square" />
                    Manpower Services
                  </li>
                </ul>
                <p />
              </div>
              <div className="one_quarter">
                <h6 className="heading">Read about us</h6>
                <ul className="nospace linklist">
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="profile">Company Profile</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Clients</h6>
                <ul className="nospace clear latestimg">
                  <li>
                    <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/7.png"
                      } alt="" />
                    </a>
                  </li>
                  <li>
                  <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/01.png"
                      } alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* ################################################################################################ */}
          </footer>
        </div>
      </div>
    );
  }
}

export default About;
