import React, { Component } from "react";
import "../../layout/styles/layout.css";

class Home extends Component {
  render() {
    return (
      <div>
        <div
          className="bgded overlay"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/demo/backgrounds/hero.jpg)`,
          }}
        >
          <div className="wrapper row0">
            <div id="topbar" className="hoc clear">
              <div className="fl_left">
                <ul className="nospace">
                  <li>
                    <i className="fas fa-phone"></i> (+91)8700230989
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>
                    gautamfacilityservices@gmail.com
                  </li>
                </ul>
              </div>
              <div className="fl_right">
                <ul className="nospace">
                  <li>
                    <a href="index">
                      <i className="fas fa-home"></i>
                    </a>
                  </li>
                  <li>
                    <a href="Contact" title="Help Centre">
                      <i className="fas fa-life-ring"></i>
                    </a>
                  </li>
                  <li>
                    <a href="signin" title="Login">
                      <i class="fas fa-sign-in-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="wrapper row1">
            <header id="header" className="hoc clear">
              <div id="logo" className="fl_left">
                <h1>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/demo/backgrounds/company.png"
                    }
                    style={{ width: "65px"}}
                  />
                  <a href="home">Gautam Facility Services</a>
                </h1>
              </div>
              <nav id="mainav" className="fl_right">
                <ul className="clear">
                  <li className="active">
                    <a href="home">Home</a>
                  </li>
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="services">Our Services</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </nav>
            </header>
          </div>
          <div id="pageintro" className="hoc clear">
            <article className="center">
              <h3 className="heading underline">
                Deals in Manpower and Facility Services
              </h3>
              <h5>
                Gautam Facility Services Team have desire to repeatedly exceed service
                expectation of customers
              </h5>
              <footer>
                <a className="btn" href="about">
                  Learn More
                </a>
              </footer>
            </article>
          </div>
        </div>

        <div className="wrapper row4">
          <footer id="footer" className="hoc clear">
            <hr className="btmspace-50" />
            <div className="group">
              <div className="one_quarter first">
                <h6 className="heading">Contact info</h6>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    <address style={{marginRight: "-12px"}}>
                    N-12 K.N Complex Lajpat Nagar - 2 
                    New Delhi-110024
                    </address>
                  </li>
                  <li>
                    <i className="fas fa-phone"></i>(+91)8700230989,
                    <br /> (011) 35545223
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>{" "} <p style={{marginRight: "-6px"}}>gautamfacilityservices@gmail.com</p>
                  </li>
                </ul>
                <ul className="faico clear">
                  <li>
                    <a className="faicon-facebook" href="#">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a className="faicon-google-plus" href="#">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li>
                  <li>
                    <a className="faicon-linkedin" href="#">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a className="faicon-twitter" href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a className="faicon-vk" href="#">
                      <i className="fab fa-vk"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Services</h6>
                <p className="nospace btmspace-15">
                  <ul className="nospace btmspace-30 linklist contact">
                    <li>
                      <i className="fas fas fa-check-square"></i>
                      Hospital Housekeeping Services
                    </li>
                    <li>
                      <i className="fas fas fa-check-square"></i>
                      Manpower Services
                    </li>
                  </ul>
                </p>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Read about us</h6>
                <ul className="nospace linklist">
                  <li>
                    <a href="./about">About</a>
                  </li>
                  <li>
                    <a href="./profile">Company Profile</a>
                  </li>
                  <li>
                    <a href="./clients">Clients</a>
                  </li>
                  <li>
                    <a href="./compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="./whyus">Why Us?</a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Clients</h6>
                <ul className="nospace clear latestimg">
                  <li>
                    <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/7.png"
                      } alt="" />
                    </a>
                  </li>
                  <li>
                  <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/01.png"
                      } alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Home;
