import React, { Component } from "react";

class WhyUs extends Component {
  render() {
    return (
      <div>
        <div
          className="bgded overlay"
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1545043565-42d7d40b2a9a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit&w=1050&q=80")',
          }}
        >
          {/* ################################################################################################ */}
          <div className="wrapper row0">
            <div id="topbar" className="hoc clear">
              {/* ################################################################################################ */}
              <div className="fl_left">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <i className="fas fa-phone" /> (+91)8700230989
                  </li>
                  <li>
                    <i className="far fa-envelope" />
                    gautamfacilityservices@gmail.com
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              <div className="fl_right">
                {/* ################################################################################################ */}
                <ul className="nospace">
                  <li>
                    <a href="../home">
                      <i className="fas fa-home" />
                    </a>
                  </li>
                  <li>
                    <a href="contact" title="Help Centre">
                      <i className="fas fa-life-ring" />
                    </a>
                  </li>
                  <li>
                    <a href="signin" title="Login">
                      <i class="fas fa-sign-in-alt"></i>
                    </a>
                  </li>
                </ul>
                {/* ################################################################################################ */}
              </div>
              {/* ################################################################################################ */}
            </div>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div className="wrapper row1">
            <header id="header" className="hoc clear">
              {/* ################################################################################################ */}
              <div id="logo" className="fl_left">
                <h1>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/demo/backgrounds/company.png"
                    }
                    style={{  width: "65px" }}
                  />
                  <a href="../home">Gautam Facility Services</a>
                </h1>
              </div>
              {/* ################################################################################################ */}
              <nav id="mainav" className="fl_right">
                <ul className="clear">
                  <li>
                    <a href="home">Home</a>
                  </li>
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="services">Our services</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li className="active">
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </nav>
              {/* ################################################################################################ */}
            </header>
          </div>
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          {/* ################################################################################################ */}
          <div id="breadcrumb" className="hoc clear">
            {/* ################################################################################################ */}
            <h6 className="heading">Why Us?</h6>
            {/* ################################################################################################ */}
          </div>
          {/* ################################################################################################ */}
        </div>
        {/* End Top Background Image Wrapper */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row3">
          <main className="hoc container clear">
            {/* main body */}
            {/* ################################################################################################ */}
            <div className="content">
              {/* ################################################################################################ */}
              <h1>Why Us? </h1>
              <p>
                With our reliable services, we have been successful in attaining
                huge market appreciations. We are now recognized as prominent
                caterers and other facility service providers of India.
                Moreover, we maintain cordial relations with our clients and
                take various initiatives to satisfy them. Some of reasons for
                which we are trusted by our patrons are:
                <br />
                <br />
                 Client‐centric approach
                <br />
                 Transparent dealings
                <br />
                 Professional and Secure
                <br />
                 Satisfaction Guarantee
                <br />
                 Reliable staff
                <br />
                 Purity in taste and quality of the food served
                <br />
                 Effective and efficient utilization of Manpower
                <br />
              </p>
            </div>
            {/* ################################################################################################ */}
          </main>
        </div>
        {/* ################################################################################################ */}
        {/* / main body */}
        <div className="clear" />
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        {/* ################################################################################################ */}
        <div className="wrapper row4">
          <footer id="footer" className="hoc clear">
            {/* ################################################################################################ */}
            <hr className="btmspace-50" />
            {/* ################################################################################################ */}
            <div className="group">
              <div className="one_quarter first">
                <h6 className="heading">Contact info</h6>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <address style={{marginRight: "-12px"}}>
                    N-12 K.N Complex Lajpat Nagar - 2 
                    New Delhi-110024
                    </address>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    (+91)8700230989,
                    <br /> (011) 29831411
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>{" "} <p style={{marginRight: "-6px"}}>gautamfacilityservices@gmail.com</p>
                  </li>
                </ul>
                <ul className="faico clear">
                  <li>
                    <a className="faicon-facebook" href="#">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-google-plus" href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-linkedin" href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-twitter" href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a className="faicon-vk" href="#">
                      <i className="fab fa-vk" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Services</h6>
                <p className="nospace btmspace-15"></p>
                <ul className="nospace btmspace-30 linklist contact">
                  <li>
                    <i className="fas fas fa-check-square" />
                    Hospital Housekeeping Services
                  </li>
                 
                  <li>
                    <i className="fas fas fa-check-square" />
                    Manpower Services
                  </li>
                </ul>
                <p />
              </div>
              <div className="one_quarter">
                <h6 className="heading">Read about us</h6>
                <ul className="nospace linklist">
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="profile">Company Profile</a>
                  </li>
                  <li>
                    <a href="clients">Clients</a>
                  </li>
                  <li>
                    <a href="compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="whyus">Why Us?</a>
                  </li>
                </ul>
              </div>
              <div className="one_quarter">
                <h6 className="heading">Clients</h6>
                <ul className="nospace clear latestimg">
                  <li>
                    <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/7.png"
                      } alt="" />
                    </a>
                  </li>
                  <li>
                  <a className="imgover" href="../clients">
                      <img 
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/demo/gallery/hospitals/01.png"
                      } alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* ################################################################################################ */}
          </footer>
        </div>
      </div>
    );
  }
}

export default WhyUs;
