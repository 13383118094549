import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs  } from "firebase/firestore";
import { getStorage, ref, listAll } from 'firebase/storage';
import { getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCExPxFektAcw-VziwTdi70lZ2MsokH3PE",
  authDomain: "gautam-facility-services.firebaseapp.com",
  projectId: "gautam-facility-services",
  storageBucket: "gautam-facility-services.appspot.com",
  messagingSenderId: "798418180281",
  appId: "1:798418180281:web:a683058135c1ea536a2368",
  databaseURL: "https://console.firebase.google.com/u/1/project/gautam-facility-services/database/gautam-facility-services-default-rtdb/data/~2F"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

// const listAllFiles = async () => {
//   const storageRef = ref(storage, '/'); // Reference to the root of the storage

//   try {
//     const items = await listAll(storageRef);

//     items.items.forEach((item) => {
//       console.log('File:', item.name);
//     });
//   } catch (error) {
//     console.error('Error listing files:', error);
//   }
// };

// listAllFiles();

export default app;